<script>


/**
 * Basic Info Edit component
 */



import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
    required,
} from "vuelidate/lib/validators";
import simplebar from "simplebar-vue";
import { getCashApi } from '@/api/cash'
import { financial } from '@/api/misc'


export default {

    emits: ['confirm', 'cancel'],

    props: ['transactions', 'bank_account'],
    components: {
        flatPickr,
        simplebar
    },

    validations: {
        post_date : {required}
    },



    data() {

        return {
            bank_account_id : '',
            bank_account_list : [],
            bank_account_choice : {},
            amount : 0,
            desc : '',
            post_date : '',
            is_submitting : false,
            submit_progress : 10,
            task_interval_id : ''
        }

    },
    methods: {

        f(v, d=2) {
            return financial(v, d)
        },


        formSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid == false) {
                this.submit_progress = 10
                this.is_submitting   = true

                if (this.task_interval_id) {
                    clearInterval(this.task_interval_id)
                }

                this.task_interval_id = setInterval(() => {
                    if (this.submit_progress < 90) {
                        this.submit_progress += 5
                    }
                }, 1000);

                let data = {
                    transactions : this.transactions,
                    bank_account_id : this.bank_account_id,
                    remarks : this.desc,
                    post_date  :this.post_date
                }
                getCashApi().make_eft_payment(data).then((res) => {
                    clearInterval(this.task_interval_id)
                    
                    this.is_submitting = false
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Error  "+ res.errCode);
                    }
                })
            }
        },


    },

    computed: {
       
    },

    created() {


    },

    mounted() {
        this.bank_account_choice = new Choices('#choices-bank-account', { })
        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                  
                    this.bank_account_list.push({
                        label : b.name,
                        value : b.id,
                        bank_account : b,
                        selected : b.account_type == this.bank_account? true:false
                    })
                    if (b.account_type == this.bank_account) {
                         this.bank_account_id = b.id
                    }
                })

                this.bank_account_choice.setChoices(this.bank_account_list)
              
            }
        })

        if (this.transactions) {
            this.amount = this.f(this.transactions.reduce((a, c) => a += c.amount, 0), 2)
        }
        

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account" id="choices-bank-account" :disabled="this.bank_account? true : false">
                            </select>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Post Date</label>
                            <flat-pickr
                                v-model="post_date"
                                placeholder="Select a date"
                                class="form-control"
                                :class="{'is-invalid':  $v.post_date.$error }"
                                ></flat-pickr>
                          <div v-if=" $v.post_date.$error" class="invalid-feedback">
                            <span v-if="!$v.post_date.required">This value is required.</span>
                          </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01" v-model="amount" type="text" class="form-control"
                                placeholder="$"  readonly />
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="validationCustom01">Description</label>
                            <input id="validationCustom01" v-model="desc" type="text" class="form-control"
                                placeholder="" value="" />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row" v-if="is_submitting">
                    <b-progress height="20px" :value="submit_progress"></b-progress>
                </div>

                <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive" style="max-height: 400px;">
                        <div class="table align-middle table-nowrap" style="height:100%" >
                            <b-table :items="transactions" :fields="['Payee','Amount']" responsive="sm" :per-page="transactions.length" 
                            class="table-check">

                            
                                <template #cell(Amount)="data">
                                    {{ data.item.amount }}
                                </template>

                                <template #cell(Payee)="data">
                                    {{ data.item.payee }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </simplebar>

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit" :disabled="is_submitting">Confirm EFT</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>